var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('html', {
    attrs: {
      "lang": "en"
    }
  }, [_c('head', [_c('meta', {
    attrs: {
      "charset": "utf-8"
    }
  }), _c('title', [_vm._v(_vm._s(_vm.$t('invoice')))]), _c('link', {
    attrs: {
      "rel": "stylesheet",
      "href": "@/assets/css/invoice.css",
      "media": "all",
      "type": "text/css"
    }
  })]), _vm._m(0)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('body', [_c('header', {
    staticClass: "clearfix"
  }, [_c('div', {
    attrs: {
      "id": "logo"
    }
  }, [_c('img', {
    attrs: {
      "src": "logo.png"
    }
  })]), _c('h1', [_vm._v("INVOICE 3-2-1")]), _c('div', {
    staticClass: "clearfix",
    attrs: {
      "id": "company"
    }
  }, [_c('div', [_vm._v("Company Name")]), _c('div', [_vm._v("455 Foggy Heights,"), _c('br'), _vm._v(" AZ 85004, US")]), _c('div', [_vm._v("(602) 519-0450")])]), _c('div', {
    attrs: {
      "id": "project"
    }
  }, [_c('div', [_c('span', [_vm._v("PROJECT")]), _vm._v(" Website development")]), _c('div', [_c('span', [_vm._v("CLIENT")]), _vm._v(" John Doe")]), _c('div', [_c('span', [_vm._v("ADDRESS")]), _vm._v(" 796 Silver Harbour, TX 79273, US")]), _c('div', [_c('span', [_vm._v("EMAIL")]), _vm._v(" "), _c('a', {
    attrs: {
      "href": "mailto:john@example.com"
    }
  }, [_vm._v("john@example.com")])]), _c('div', [_c('span', [_vm._v("DATE")]), _vm._v(" August 17, 2015")]), _c('div', [_c('span', [_vm._v("DUE DATE")]), _vm._v(" September 17, 2015")])])]), _c('main', [_c('table', [_c('thead', [_c('tr', [_c('th', {
    staticClass: "service"
  }, [_vm._v("SERVICE")]), _c('th', {
    staticClass: "desc"
  }, [_vm._v("DESCRIPTION")]), _c('th', [_vm._v("PRICE")]), _c('th', [_vm._v("QTY")]), _c('th', [_vm._v("TOTAL")])])]), _c('tbody', [_c('tr', [_c('td', {
    staticClass: "service"
  }, [_vm._v("Design")]), _c('td', {
    staticClass: "desc"
  }, [_vm._v("Creating a recognizable design solution based on the company's existing visual identity")]), _c('td', {
    staticClass: "unit"
  }, [_vm._v("$40.00")]), _c('td', {
    staticClass: "qty"
  }, [_vm._v("26")]), _c('td', {
    staticClass: "total"
  }, [_vm._v("$1,040.00")])]), _c('tr', [_c('td', {
    staticClass: "service"
  }, [_vm._v("Development")]), _c('td', {
    staticClass: "desc"
  }, [_vm._v("Developing a Content Management System-based Website")]), _c('td', {
    staticClass: "unit"
  }, [_vm._v("$40.00")]), _c('td', {
    staticClass: "qty"
  }, [_vm._v("80")]), _c('td', {
    staticClass: "total"
  }, [_vm._v("$3,200.00")])]), _c('tr', [_c('td', {
    staticClass: "service"
  }, [_vm._v("SEO")]), _c('td', {
    staticClass: "desc"
  }, [_vm._v("Optimize the site for search engines (SEO)")]), _c('td', {
    staticClass: "unit"
  }, [_vm._v("$40.00")]), _c('td', {
    staticClass: "qty"
  }, [_vm._v("20")]), _c('td', {
    staticClass: "total"
  }, [_vm._v("$800.00")])]), _c('tr', [_c('td', {
    staticClass: "service"
  }, [_vm._v("Training")]), _c('td', {
    staticClass: "desc"
  }, [_vm._v("Initial training sessions for staff responsible for uploading web content")]), _c('td', {
    staticClass: "unit"
  }, [_vm._v("$40.00")]), _c('td', {
    staticClass: "qty"
  }, [_vm._v("4")]), _c('td', {
    staticClass: "total"
  }, [_vm._v("$160.00")])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v("SUBTOTAL")]), _c('td', {
    staticClass: "total"
  }, [_vm._v("$5,200.00")])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v("TAX 25%")]), _c('td', {
    staticClass: "total"
  }, [_vm._v("$1,300.00")])]), _c('tr', [_c('td', {
    staticClass: "grand total",
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v("GRAND TOTAL")]), _c('td', {
    staticClass: "grand total"
  }, [_vm._v("$6,500.00")])])])]), _c('div', {
    attrs: {
      "id": "notices"
    }
  }, [_c('div', [_vm._v("NOTICE:")]), _c('div', {
    staticClass: "notice"
  }, [_vm._v("A finance charge of 1.5% will be made on unpaid balances after 30 days.")])])]), _c('footer', [_vm._v(" Invoice was created on a computer and is valid without the signature and seal. ")])]);

}]

export { render, staticRenderFns }